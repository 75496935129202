import React from "react"
import { Link, PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicyPage: React.FC<PageProps> = ({ location }) => (
  <Layout location={location}>
    <SEO title="Privacy Policy" description="" />
    <h3>COMPOSITE PRIVACY POLICY</h3>
    <h4>Last Modified: July 28, 2020</h4>
    <ol>
      <li>
        <h4>Introduction</h4>
        <p>
          Composite Financial, Inc. (“Composite”) is dedicated to protecting your personal information and informing you about how we use your personal information. This privacy policy applies to your use of the Composite Services and Platform (as defined by the Subscriber Agreement and Terms of Use). This Privacy Policy should be read in conjunction with the Subscriber Agreement and Terms of Use and is integrated into the Subscriber Agreement and Terms of Use.  All capitalized proper nouns not defined in this Privacy Policy will have the same definitions and meanings as defined by the Subscriber Agreement and Terms of Use. Please review this Privacy Policy periodically as we may revise it from time to time. If you do not agree with or accept our Privacy Policy in it’s entirety, you must not access or use the Platform. If you use the Platform following a change to the terms of this Privacy Policy you agree to accept the revised policies.
        </p>
      </li>
      <li>
        <h4>Information Collected</h4>
        <p>
        At Composite, we collect personally identifiable information (“PII”) and non-personally identifiable (“Non-PII”) information from you.  Personally identifiable information is information that can be used to identify you personally.  Non-personally identifiable information is information that must be combined with other information to identify you personally.<br/><br/>You will not be required to provide us any information when you visit our Platform.  However, in order to access or use some portions of the Platform, we may collect PII such as your name, additional identifying information, email, telephone number, personal or business details, date of birth, login and password information, address, and financial information.  We may also collect your relevant payment or credit card information if you wish to pay for any services offered via the Platform.  Please be aware that all payment information shall be stored and processed by our third party payment processors.<br/><br/>Whenever you use our website, we may collect Non-PII from you, such as your IP address, zip code, browsing history, search history, and registration history, interactions with the Platform, usage information, location, referring URL, browser, operating system, data usage, data transferred, and Internet service provider.
        </p>
      </li>
      <li>
        <h4>Use of Your Information</h4>
        <p>
        Some of your information will be visible to other users of the Platform to facilitate communication. We will never sell your information without your permission; however you agree that we may use your information in the following ways: <br/><br/>
        <ul>
          <li>To provide any services offered and to operate Composite Platform.</li>
          <li>To enhance or improve our users’ experiences.</li>
          <li>To contact you via email or other electronic communications where you have an inquiry.</li>
          <li>To notify you of additional Composite services and updates.</li>
          <li>To share with third parties, with whom you have requested additional information relating to their products and services.</li>
          <li>To process your transactions.</li>
          <li>To share your information with third party partners or third parties hired by us to perform functions and provide services to us subject to the obligations consistent with this Privacy Policy.</li>
        </ul>
        </p>
      </li>
      <li>
        <h4>Anonymized Data</h4>
        <p>
        Please be aware that we may collect and aggregate personally identifiable information from our Platform and may anonymize that information for our own research or internal purposes.  Once such data has been anonymized, it cannot be traced back to you, the user.
        </p>
      </li>
      <li>
        <h4>Accessing and Editing Your Information</h4>
        <p>
        You will be able to access any information contained in your account through our Platform.  You may edit that information by changing the information listed in your account. If you have any questions or wish to review, change, or access any of your information collected by us, please contact us at info@comfi.us. After you have cancelled your account please be aware that we may keep inaccessible copies of your PII and non-PII subject to our data retention policies.
        </p>
      </li>
      <li>
        <h4>Cookies and Tracking</h4>
        <p>
        We use cookies for authentication purposes and to remember you when you visit our Platform. Additionally, some third party cookies integrated into our Platform may track your usage information and interactions with the Platform. If you disable cookies from your web browser some portions of our Platform may not work.
        </p>
      </li>
      <li>
        <h4>Third Party Access to Your Information</h4>
        <p>
        Although you are entering into an agreement with Composite to disclose your information to us, we do use third party individuals and organizations to assist us, including contractors, web hosts, and others to allow you to access the Platform.  Specifically, we use Plaid, Amazon Web Services, and UserSnap. <br/><br/>Throughout the course of our provision of our services to you, we may delegate our authority to collect, access, use, and disseminate your information.  It is therefore necessary that you grant the third parties we may use in the course of our business the same rights that you afford us under this Privacy Policy.  For this reason, you hereby agree that for every authorization which you grant to us in this Privacy Policy, you also grant to any third party that we may hire, contract, or otherwise retain the services of for the purpose of operating, maintaining, repairing, or otherwise improving or preserving our website or its underlying files or systems.  You agree not to hold us liable for the actions of any of these third parties, even if we would normally be held vicariously liable for their actions, and that you must take legal action against them directly should they commit any tort or other actionable wrong against you. 
        </p>
      </li>
      <li>
        <h4>Law Enforcement</h4>
        <p>
        You agree that we may disclose your information to authorities if compelled to by a court order.  Additionally, you agree that we may disclose your information if we reasonably believe that you have violated US laws, the terms of our Subscriber Agreement and Terms of Use or our Privacy Policy, or if we believe that a third party is at risk of bodily or economic harm.   In the event that we receive a subpoena affecting your privacy, we may elect to notify you to give you an opportunity to file a motion to quash the subpoena, or we may attempt to quash it ourselves, but we are not obligated to do either.  We may also proactively report you and release your information without receiving any request to third parties where we believe that it is proper to do so for legal reasons, where your actions violate any law of the United States or any other country having jurisdiction over us, our Platform, or our Subscriber Agreement and Terms of Use.  You release us from any damages that may arise from or relate to the release of your information to a request from law enforcement agencies or private litigants.  We may release your information under the conditions listed in this paragraph whether it is to individuals or entities and to any state or federal authorities, as required.
        </p>
      </li>
      <li>
        <h4>Opt Out of Commercial, Non-Commercial Communications and Do Not Track</h4>
        <p>
        If you decide to provide us with your contact information, you agree that we may send you communications via text and emails.  However, you may unsubscribe from certain communications by notifying Composite that you no longer wish to receive these communications, we will endeavour to promptly remove you from our Platform once we have received that request.  We currently do not offer functionality for you to opt out through “do not track” listings. If you wish to opt out of certain communications or information collection, please contact us at info@comfi.us.  
        </p>
      </li>
      <li>
        <h4>Third Parties</h4>
        <p>
        Please be aware that some third party websites may be accessible via the Platform, when accessing a third party site through our Platform, you acknowledge that you are aware that these third party websites are not screened for privacy or security issues by us, and you release us from any liability for the conduct of these third party websites.<br/><br/>Please be aware that this Privacy Policy, and any other policies in place, in addition to any amendments, does not create rights enforceable by third parties.  Composite bears no responsibility for the information collected or used by any advertiser or third party website. You must review their policies to understand how their information collection practices work.
        </p>
      </li>
      <li>
        <h4>Security Measures</h4>
        <p>
        We make reasonable attempts to protect your information by using physical and electronic safeguards.  However, as this is the Internet, we can make no guarantees as to the security or privacy of your information.  For this reason, we recommend that you use anti-virus software, routine credit checks, firewalls, and other precautions to protect yourself from security and privacy threats.
        </p>
      </li>
      <li>
        <h4>Your California Privacy Rights</h4>
        <p>
        Composite permits residents of the State of California to use its Platform, and complies with the California Business and Professions Code §§ 22575-22579.  If you are a California resident you may request certain information regarding our disclosure of personal information to any third parties for their direct marketing purposes.  Various provisions throughout this Privacy Policy address requirements of the Californian privacy statutes. Although we do not disseminate your information to third parties without permission, you must presume that we collect electronic information from all visitors. You may contact us at info@comfi.us with any questions.
        </p>
      </li>
      <li>
        <h4>Age Compliance</h4>
        <p>
        We intend to fully comply with American and international laws respecting children’s privacy including COPPA.  Therefore, we do not collect or process any information for any persons under the age of 18.  If you are under 18 and using our Platform, please stop immediately and do not submit any information to us.   In the event that we have inadvertently collected any information from users under the age of 18 please contact us immediately.
        </p>
      </li>
      <li>
        <h4>International Transfer</h4>
        <p>
          Your information may be transferred to - and maintained on - computers located outside of your state, province, country, or other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction.  Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.  PII and Non-PII that is submitted to Composite will be collected, processed, stored, disclosed and disposed of in accordance with applicable U.S. law and this policy. If you are a non-U.S. member, you acknowledge and agree that Composite may collect and use your Information and disclose it to other entities outside your resident jurisdiction. In addition, such information may be stored on servers located outside your resident jurisdiction. U.S. law may not provide the degree of protection for information that is available in other countries.
        </p>
      </li>
      <li>
        <h4>Merger and Acquisition</h4>
        <p>
        In the event that Composite is involved in a bankruptcy, merger, acquisition, reorganization or sale of assets, your information may be sold or transferred as part of that transaction. Please be aware that once the information is transferred your privacy rights may change.
        </p>
      </li>
      <li>
        <h4>Amendments</h4>
        <p>
        Like our Subscriber Agreement and Terms of Use, we may amend this Privacy Policy from time to time.  When we amend this Privacy Policy, we will modify the date listed on this Privacy Policy or we may contact you.  You must agree to the amendments as a condition of your continued use of our Platform.  If you do not agree, you must immediately cease using our Platform and notify us of your refusal to agree by e-mailing us at info@comfi.us.
        </p>
      </li>
    </ol>
  </Layout>
)

export default PrivacyPolicyPage
